import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './Table.module.css'
import { useTranslation } from 'react-i18next'
import Link, { navigate } from '../Link'
import * as exchDataActions from '../../store/actions/exchDataActions'
import * as currenciesActions from '../../store/actions/currenciesActions'
import { createTheme } from '@mui/material/styles'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { useStaticQuery, graphql } from 'gatsby'
import makeStyles from '@mui/styles/makeStyles'
import { Title } from '../../components/Core'
import { Spinner } from 'react-bootstrap'
import { setTableRows } from '../../store/actions/userActions'
import WithTheme from '../../helpers/WithTheme'
import MiniChartRangeSelector from './MiniChartRangeSelector'

const MUIDataTable = loadable(() => import('mui-datatables'))
const TableCell = loadable(() => import('@mui/material/TableCell'))
const TableRow = loadable(() => import('@mui/material/TableRow'))
const TableFooter = loadable(() => import('@mui/material/TableFooter'))


function createData(
  Favorites,
  currencyLogo,
  Alphabetical,
  baseCurrencyName,
  last,
  percentChange,
  Volume,
  coinigy,
  exchCode,
  marketName,
  baseCurrencyCode,
  quoteCurrencyCode,
  exchSlug
) {
  return {
    Favorites,
    currencyLogo,
    Alphabetical,
    baseCurrencyName,
    last,
    percentChange,
    Volume,
    coinigy,
    exchCode,
    marketName,
    baseCurrencyCode,
    quoteCurrencyCode,
    exchSlug,
  }
}

const useStyles = makeStyles(theme => ({
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      //   backgroundColor:'red',
    },
    '& div': {
      textAlign: 'right',
    },
  },
  centerHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //   backgroundColor:'red',
    },
    '& div': {
      textAlign: 'center',
    },
  },
}))

const ExchMarketsTableWithFooter = props => {
  const { t, i18n } = useTranslation(['tables'], { useSuspense: false })
  const exchMarketData = useSelector(state => state.exchData.exchMarketData)
  const totalRecords = useSelector(state => state.exchData.totalRecords)
  const isLoadingMarketData = useSelector(state => state.exchData.loadingMarketData);
  const currSign = useSelector(state => state.user.currSign)
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const userCurrency = useSelector(state => state.user.selectedCurr)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const [pageNumber, setPageNumber] = useState(1)
  // const [pageSize, setPageSize] = useState(50);
  const tableRows = useSelector(state => state.user.tableRows)
  const [sortBy, setSortBy] = useState('Favorites')
  const [sortOrder, setSortOrder] = useState('desc')
  const [searchTerm, setSearchTerm] = useState(null)
  const [miniChartRange, setMiniChartRange] = useState('SevenDay')

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  useEffect(() => {
    dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
  }, [dispatch, userCurrency])

  const exchSlug = props.exchSlug

  useEffect(() => {
    dispatch(
      exchDataActions.fetchExchMarketData(
        searchTerm,
        exchSlug,
        sortBy,
        sortOrder,
        tableRows,
        pageNumber,
        miniChartRange
      )
    )
    const interval = setInterval(() => {
      dispatch(
        exchDataActions.fetchExchMarketData(
          searchTerm,
          exchSlug,
          sortBy,
          sortOrder,
          tableRows,
          pageNumber,
          miniChartRange
        )
      )
    }, 90000)
    return () => clearInterval(interval)
  }, [
    dispatch,
    searchTerm,
    exchSlug,
    sortBy,
    sortOrder,
    tableRows,
    pageNumber,
    miniChartRange,
  ])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const optionsWithFooter = {
    filter: false,
        download: false,
        print: false,
        viewColumns: false,
        rowsPerPageOptions: [10, 30, 50, 100],
        serverSide: true,
        count: totalRecords,
        customToolbar: () => (
          <MiniChartRangeSelector 
            setMiniChartRange={setMiniChartRange} 
            miniChartRange={miniChartRange}
            loading={isLoadingMarketData}
          />
        ),
        onTableChange: (action, tableState) => {
          // console.log('ACTION', action, 'STATE', tableState)
          switch (action) {
            case 'changePage':
              setPageNumber(tableState.page + 1)
              break
            case 'changeRowsPerPage':
              // setPageSize(tableState.rowsPerPage);
              dispatch(setTableRows(tableState.rowsPerPage))
              break
            case 'sort':
              setSortBy(tableState.sortOrder.name)
              setSortOrder(tableState.sortOrder.direction)
              break
            case 'search':
              tableState.searchText !== null && tableState.searchText.length > 2
                ? setSearchTerm(tableState.searchText.replace(/\s+/g, ''))
                : setSearchTerm(null)
              break
            case 'onSearchClose':
              setFilteredMkts([])
              break
            default:
              return
          }
        },
        textLabels: {
          body: {
            noMatch: completed ? (
              t('noMatchMarkets')
            ) : (
              <div style={{ alignItems: 'center', minHeight: '200px' }}>
                <Spinner
                  variant={userDarkMode ? 'light' : 'dark'}
                  animation="border"
                />
              </div>
            ),
            toolTip: t('sort'),
            columnHeaderTooltip: column => `${t('sortBy')} ${column.label}`,
          },
          toolbar: {
            search: t('search'),
            // downloadCsv: t('downloadCSV'),
            // print: t('print'),
            // viewColumns: t('viewColumns'),
            filterTable: t('filter'),
          },
          pagination: {
            next: t('nextPage'),
            previous: t('previousPage'),
            rowsPerPage: t('rowsPerPage'),
            displayRows: t('of'), // 1-10 of 30
          },
          viewColumns: {
            title: t('showColumns'),
            titleAria: t('showColumnsAria'),
          },
        },

        filterType: 'multiselect',
        responsive: 'standard',
        selectableRowsHeader: false,
        selectableRows: 'none',
        customRowRender: (data, rowIndex) => {
          let marketNameLowCase = data[9].toLowerCase()
          let exchSlug = data[12]
          const handleRowClick = () =>
            navigate(`/${lang}/markets/${exchSlug}/${marketNameLowCase}/`)
          return (
            <TableRow onClick={handleRowClick} hover={true} key={data[0]}>
              <TableCell>
                <Link
                  aria-label={`${exchSlug}/${marketNameLowCase}`}
                  to={`/${lang}/markets/${exchSlug}/${marketNameLowCase}/`}
                >
                  <span
                    style={{
                      color: userDarkMode ? '#FAFAFA' : '#191B20',
                      fontFamily: 'CircularStd, sans-serif',
                    }}
                  >
                    {data[0]}
                  </span>
                </Link>
              </TableCell>
              <TableCell
                style={{
                  background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
                  paddingRight: 0,
                }}
              >
                {data[1]}
              </TableCell>
              <TableCell
                style={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  left: '0',
                  zIndex: 100,
                  background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
                  color: userDarkMode ? '#FAFAFA' : '#191B20',
                  fontWeight: 700,
                  fontSize: 16,
                }}
              >
                {data[2]}
              </TableCell>
              <TableCell
                style={{
                  color: userDarkMode
                    ? 'rgba(255, 255, 255, 0.56)'
                    : 'rgba(0, 0, 0, 0.56)',
                }}
              >
                {data[3]}
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <span
                  style={{
                    color: userDarkMode ? '#FAFAFA' : '#191B20',
                    fontVariant: 'tabular-nums',
                  }}
                >
                  {data[4]}
                </span>
              </TableCell>
              <TableCell
                style={{
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                {data[5]}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'right',
                  color: userDarkMode ? '#FAFAFA' : '#191B20',
                  fontVariant: 'tabular-nums',
                }}
              >
                {data[6] >= 1
                  ? currSign +
                    ' ' +
                    data[6].toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })
                  : data[6] === 0
                  ? '-'
                  : currSign +
                    ' ' +
                    data[6].toLocaleString(undefined, {
                      minimumFractionDigits: 8,
                    })}
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <a
                  key={rowIndex}
                  className={styles.aTagButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.LS_APP_URL}/markets/${data[8]}/${data[9]}`}
                >
                  {t('launch')}
                </a>
              </TableCell>
            </TableRow>
          )
        },
        rowsPerPage: tableRows,
  }

 
  let rowMarketName
  let rowExchCode

  const classes = useStyles()

  const columns = [
    {
      name: 'Favorites',
      label: `${t('rank')}`,
      options: {
        filter: false,
        sort: true,

        setCellProps: () => ({
          align: 'right',
          style: {
            opacity: 0.54,
            width: '1rem',
          },
        }),
      },
    },
    {
      name: 'currencyLogo',
      label: `${t('logo')}`,
      options: {
        filter: false,
        sort: false,

        setCellProps: () => ({
          style: {
            paddingRight: 0,
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
          },
        }),
        customHeadRender: ({ index, ...column }) => {
          return <TableCell key={index}></TableCell>
        },
      },
    },
    {
      name: 'Alphabetical',
      label: `${t('pairs')}`,
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: {
            fontWeight: 700,
            fontSize: 16,
            position: 'sticky',
            left: '0',
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
            zIndex: 101,
            // color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
        }),
      },
    },
    {
      name: 'baseCurrencyName',
      label: `${t('currencies')}`,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'last',
      label: `${t('price')}`,
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
          //   style: {
          //       color: userDarkMode ? '#FAFAFA' : '#191B20',
          //   }
        }),
        setCellProps: () => ({
          //   align: "right",
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
      },
    },
    {
      name: 'percentChange',
      label: `${t(`${miniChartRange}`)}`,
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centerHeader,
          // style: {
          //     color: userDarkMode ? '#FAFAFA' : '#191B20',
          // }
        }),
        setCellProps: () => ({
          style: {
            paddingTop: 6,
            paddingBottom: 6,
          },
        }),
      },
    },
    {
      name: 'Volume',
      label: `${t('volume24')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
          // style: {
          //     color: userDarkMode ? '#FAFAFA' : '#191B20',
          // }
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
          },
        }),
      },
    },
    {
      name: 'coinigy',
      label: `${t('app')}`,
      options: {
        filter: false,
        sort: false,

        setCellProps: () => ({
          align: 'right',
          style: {
            width: '1rem',
            paddingTop: 12,
            paddingBottom: 12,
          },
        }),
        customBodyRenderLite: rowIndex => {
          rowExchCode = data[rowIndex].exchCode
          rowMarketName = data[rowIndex].marketName
          return (
            <a
              key={rowIndex}
              className={styles.aTagButton}
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.LS_APP_URL}/markets/${rowExchCode}/${rowMarketName}`}
            >
              {t('launch')}
            </a>
          )
        },
      },
    },
    {
      name: 'exchCode',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'marketName',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'baseCurrencyCode',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'quoteCurrencyCode',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'exchSlug',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ]

  // const [totalRecords, setTotalRecords] = useState(100)
  // useEffect(() => {
  //   if(typeof exchMarketData[0] !== 'undefined') {
  //     console.log('AAA')
  //     setTotalRecords(exchMarketData[0].totalRecords)
  //   }
  // }, [])

  // let totalRecords;

  // typeof exchMarketData[0] !== 'undefined' ?
  //   totalRecords = exchMarketData[0].totalRecords
  //   : totalRecords = 100
    
  const [filteredMkts, setFilteredMkts] = useState([])
  
  

  let data = []

  filteredMkts.length > 0
    ? filteredMkts.map(function (item, index) {
        return data.push(
          createData(
            (pageNumber - 1) * tableRows + index + 1,
            item.currencyLogo,
            item.Alphabetical,
            item.baseCurrencyName,
            item.last,
            item.percentChange,
            item.Volume24HBtc,
            'Launch',
            item.exchCode,
            item.marketName,
            item.baseCurrencyCode,
            item.quoteCurrencyCode,
            item.exchSlug
          )
        )
      })
    : exchMarketData
    ? exchMarketData.map(function (item, index) {
        let volume24 = btcExchRate
          ? item.volume24Btc * btcExchRate
          : item.volume24Btc
        let currencyLogo = (
          <img
            width="16"
            height="16"
            className={styles.exchangeImg}
            src={
              process.env.WWW_URL +
              `/assets/img/currency/${item.baseCurrencyCode}.webP`
            }
            alt={`${item.baseCurrencyName}`}
            onError={e => {
              e.target.onerror = null
              e.target.src =
                process.env.WWW_URL + `/assets/img/currency/empty.webP`
            }}
          />
        )

        let lineChartData = []

        let percentageChange = item.percentChange * 100

        typeof item.miniChartData !== 'undefined' && item.miniChartData !== null
          ? item.miniChartData.map(function (candle) {
              return lineChartData.push(candle.price)
            })
          : (lineChartData = [])

        let last7Days = (
          <div className="d-flex justify-content-center">
            <Sparklines
              svgWidth={80}
              svgHeight={20}
              data={lineChartData}
              style={{ marginRight: 10 }}
            >
              <SparklinesLine
                style={{ strokeWidth: 6, fill: 'none' }}
                color={
                  percentageChange >= 0
                    ? userDarkMode
                      ? '#50AF7B'
                      : '#2D844B'
                    : userDarkMode
                    ? '#FB4C42'
                    : '#D0433B'
                }
              />
            </Sparklines>
            <p
              style={{
                fontVariant: 'tabular-nums',
                color:
                  percentageChange >= 0
                    ? userDarkMode
                      ? '#50AF7B'
                      : '#2D844B'
                    : userDarkMode
                    ? '#FB4C42'
                    : '#D0433B',
                fontSize: '14px',
                lineHeight: '20px',
                marginBottom: 0,
                textAlign: 'Right',
              }}
            >
              {typeof percentageChange !== 'undefined'
                ? percentageChange.toFixed(2)
                : 0}
              %
            </p>
          </div>
        )

        let price =
          item.lastTradePrice < 1 && item.lastTradePrice !== null
            ? item.quoteCurrencyCode +
              ' ' +
              item.lastTradePrice.toLocaleString(undefined, {
                minimumFractionDigits: 8,
              })
            : item.lastTradePrice !== null
            ? item.quoteCurrencyCode +
              ' ' +
              item.lastTradePrice.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })
            : '-'

        return data.push(
          createData(
            (pageNumber - 1) * tableRows + index + 1,
            currencyLogo,
            item.displayName,
            item.baseCurrencyName,
            price,
            last7Days,
            volume24,
            'Launch',
            item.exchCode,
            item.marketName,
            item.baseCurrencyCode,
            item.quoteCurrencyCode,
            item.exchSlug
          )
        )
      })
    : (data = [])
  return (
    <MUIDataTable
      title={
        <div>
          <Title
            color={userDarkMode ? '#FAFAFA' : 'dark'}
            lineHeight={'28px !important'}
            marginBottom={'0px !important'}
            letterSpacing={'-0.02em'}
          >
            {`${props.exchName} ${t('markets')}`}
          </Title>
        </div>
      }
      data={data}
      columns={columns}
      options={optionsWithFooter}
    />
  )
}

const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontFamily: 'CircularStd, sans-serif',
          },
          underline: {
            '&:before': { borderBottom: '2px solid transparent !important' },
            '&:after': { borderBottom: '2px solid #3377C6 !important' },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
          input: {
            backgroundColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)',
            paddingTop: 14,
            paddingRight: 16,
            paddingBottom: 15,
            paddingLeft: 16,
            borderRadius: 5,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          searchIcon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
          searchText: { maxWidth: 400 },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          label: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
          title: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: userDarkMode
                ? 'rgba(255, 255, 255, 0.1) !important'
                : 'rgba(0, 0, 0, 0.04) !important',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: { fontFamily: 'CircularStd, sans-serif', margin: '20px 0' },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          caption: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0em',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            opacity: 0.6,
          },
          select: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: '#3377C6',
            '&:focus': { borderRadius: '10px' },
            backgroundColor: 'transparent',
          },
          selectLabel: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          displayedRows: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          selectIcon: { color: '#3377C6' },
          actions: {
            color: userDarkMode ? '#3377C6' : '#3377C6',
            backgroundColor: 'transparent',
          },
          root: { zIndex: 999 },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif' },
          paper: {
            borderRadius: 0,
            overflow: 'hidden',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
          },
          toolButton: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
            marginRight: '-8px',
            fontWeight: 300,
          },
          fixedHeader: {
            backgroundColor: 'transparent',
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            fontWeight: 300,
          },
          data: {
            // backgroundColor: userDarkMode ? '#191B20' : 'white' ,
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          sortActive: {
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          contentWrapper: {},
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56) !important'
              : 'rgba(0, 0, 0, 0.56) !important',
            marginTop: '3px',
            marginRight: '0',
          },
        },
      },
      MuiSelected: {
        styleOverrides: {
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': {
              color: '#3377C6',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiTableFooter: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif', fontWeight: 300 },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            minHeight: '80px !important',
            paddingLeft: '0px',
            paddingRight: '0px',
            zIndex: 999,
          },
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': {
              color: '#3377C6',
            },
          },
          titleText: {
            color: userDarkMode ? '#3986F7' : '#185398',
            fontWeight: 900,
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '24px !important',
            textTransform: 'uppercase',
            letterSpacing: '-0.02em',
          },
          actions: {
            display: 'flex',
            flex: 'initial',
            '& > span, & > button': { order: 99 },
            '& > span:last-child, & > button:last-child': { order: 1 },
            '& > span:nth-child(4), & > button:nth-child(4)': { order: 2 },
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: { headerCell: { backgroundColor: 'transparent' } },
      },
      MuiTableCell: {
        styleOverrides: {
          head: { fontWeight: 300 },
          body: {
            fontSize: '14px',
            fontFamily: 'CircularStd, sans-serif',
            cursor: 'pointer',
            color: userDarkMode ? '#FAFAFA' : 'black',
          },
          root: {
            borderBottomColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1) !important'
              : 'light',
            fontFamily: 'CircularStd, sans-serif',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontWeight: 300,
          },
        },
      },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(ExchMarketsTableWithFooter)